<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12" class="mt-0 pt-0">
        <base-material-card
          color="primary"
          icon="mdi-eye-outline"
          inline
          class="px-5 py-3"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Visualização do relatório: {{ nomeRelatorio }}
            </div>
          </template>
          <v-row>
            <v-col cols="12" class="mt-1">
              <v-alert dense outlined type="info" color="blue" class="mb-0 py-0 px-1">
                Essa tabela exibe no máximo <b>500</b> registros da primeira sheet do relatório. Caso queira
                visualizar o relatório completo,
                <a href="javascript:void(0)" @click="downloadArquivoResultado($route.params.id)"
                  >clique aqui</a
                >
                para fazer o download da planilha.
              </v-alert>
            </v-col>
            <v-col cols="12" class="mt-1">
              <v-data-table
                multi-sort
                dense
                :headers="headers"
                :items="items"
                :loading="loading"
                :options="{multiSort: false}"
                loading-text="Carregando tabela"
                :headerProps="{
                  sortByText: 'Ordenar Por'
                }"
                :footer-props="{
                  itemsPerPageText: 'Itens por Página',
                  itemsPerPageOptions: [10, 50, 100, 250, 500]
                }"
                no-data-text="Nenhum registro disponível"
                no-results-text="Nenhum registro correspondente encontrado"
                :mobile-breakpoint="10"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import RelatoriosService from '@/services/RelatoriosService';
import RelatoriosCriadosService from '@/services/RelatoriosCriadosService';

export default {
  data() {
    return {
      nomeRelatorio: "",
      loading: true,
      headers: [],
      items: []
    };
  },

  mounted() {
    this.getDados();
  },

  methods: {
    getDados() {
      this.loading = true;

      RelatoriosCriadosService.visualizar(this.$route.params.id)
        .then(response => {
          this.nomeRelatorio = response.data.nomeConsulta;
          this.changeBreadcrumb();

          for (let i = 0; i < response.data.cabecalho.length; i++) {
            const nomeColuna = response.data.cabecalho[i];
            this.headers.push({
              value: String(i),
              text: nomeColuna,
              divider: false,
            });
          }

          response.data.dados.forEach(linha => {
            this.items.push(linha);
          });
        })
        .catch(err => {
          this.$toast.error("Erro na visualização do relatório.", "", {
            position: "topRight"
          });
          console.log("Erro: ", err);
        })
        .finally(() => this.loading = false);
    },
    changeBreadcrumb() {
      RelatoriosService.getRelatorioBySlug(this.$route.params.slug)
        .then(relatorio => {
          const breadcrumbData = [
            { text: 'Relatórios', to: '/relatorios', exact: true},
            { text: relatorio.nome, to: `/relatorios/${this.$route.params.slug}/historico`, exact: true },
            { text: 'Visualizar', exact: false }
          ];

          this.$route.meta.breadcrumb = breadcrumbData;

          //atualiza variavel no store, com isso o watch do appBar atualizar breadcrumb;
          this.$store.commit('WATCH_CHANGE_BREADCRUMB');
        })
        .catch(err => {
          console.log('Erro: ', err);
          this.$route.meta.breadcrumb(this.$route);
        });
    },
    downloadArquivoResultado(id) {
      this.$toast.info('Preparando seu arquivo para download.', '', {
        position: 'topRight',
        timeout: 3500,
      });

      RelatoriosCriadosService.downloadArquivoResultado(id, 'xlsx', { timeout: 60 * 60 * 1000 })
        .then(async (response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          let nomeArquivo = await this.getNomeArquivoResultado(id);
          link.setAttribute('download', `${nomeArquivo}.xlsx`);
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {

          console.error(error);
          this.$toast.error('Erro no download do arquivo.', '', {
            position: 'topRight',
            timeout: 5000
          });
        });
    },
    getNomeArquivoResultado(id) {
      return RelatoriosCriadosService
        .getNomeArquivoResultado(id)
        .then(response => response.data.nome)
        .catch(error => {
          console.error(error)
          this.$toast.error('Erro ao buscar o nome do arquivo.', '', {
            position: 'topRight',
          });
        });
    },
  },
};
</script>

<style>
.v-data-table table thead tr th {
  font-weight: 300;
  font-size: 14.5px;
  margin: 0px 0px;
  padding-left: 8px;
  padding-right: 24px;
}

.v-data-table table tbody tr td {
  font-size: 13px;
}
</style>